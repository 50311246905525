<template>
  <div class="EnterpriseSettlement">
    <!-- <div class="head banner">
      <img class="imgs" src alt />
      <span style="font-size: 16px; font-weight: 400;">服务商入驻</span>
    </div>-->
    <div class="enterpriseSettlement">
      <el-card class="box-card from-data">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm" label-position="left">
          <el-form-item label="类型" prop="serviceType">
            <el-radio-group v-model="ruleForm.serviceType" @change="serviceTypeChange" size="mini">
              <el-radio :label="1" border>个人</el-radio>
              <el-radio :label="2" border>企业</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 标签
          -->
          <el-form-item label="标签" prop="providerLabel" v-if="ruleForm.serviceType === 1">
            <el-radio-group v-model="ruleForm.providerLabel" size="mini">
              <el-radio border v-for="(item,index) in labels" :key="index" :label="item.value" :value="item.value">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="保证金" prop="earnestMoney">
            <el-input class="inputWidth" placeholder="请选择入驻类型" v-model="ruleForm.earnestMoney" disabled clearable></el-input>
          </el-form-item>
          <el-form-item label="行业" prop="serviceIndustry">
            <div slot="label" style="margin-left:10px;">行业</div>
            <el-select v-model="ruleForm.serviceIndustry" placeholder="请选择行业">
              <el-option v-for="(item, index) in serviceItems" :key="index" :label="item.itemsName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="serviceName">
            <el-input class="inputWidth" placeholder="2-15字符，不支持特殊字符" v-model="ruleForm.serviceName" clearable></el-input>
          </el-form-item>
          <el-form-item label="所在地" prop="serviceLocation">
            <el-cascader v-model="ruleForm.serviceLocation" :options="city" :props="areaProps" filterable placeholder="省、市"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="detailedAddress">
            <el-input class="inputWidth" placeholder="请输入内容" v-model="ruleForm.detailedAddress" clearable></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="contactInformation">
            <el-input class="inputWidth" placeholder="请输入内容" v-model="ruleForm.contactInformation" clearable></el-input>
          </el-form-item>
          <!-- 身份证 上传 -->
          <el-form-item label="身份证上传" prop="idcardImgz">
            <div class="idcardImg">
              <div>
                <ImageUpload v-model="ruleForm.idcardImgz" :isShowTip="false" :idcardImgShow="true" idcardImgTxt="人像面上传" :limit="1"></ImageUpload>
              </div>
              <div class="idcardImgf">
                <ImageUpload v-model="ruleForm.idcardImgf" :isShowTip="false" idcardImgTxt="国徽面上传" :idcardImgShow="true" :limit="1"></ImageUpload>
              </div>
              <div class="idcardImg-rule">请提交中华人民共和国居民身份证原件， 支持jpg.png</div>
            </div>
          </el-form-item>
          <!-- 营业执照 -->
          <!-- 判断条件
          如果是
          个人 营业执照 不是必填项
          企业 营业执照 必填
          -->
          <el-form-item label="营业执照" :prop="ruleForm.serviceType === 1 ? '' : 'licenseImg'">
            <ImageUpload v-model="ruleForm.licenseImg" :isShowTip="false"></ImageUpload>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="secondCard">
        <div class="introduce">介绍</div>
        <el-card class="box-card">
          <editor v-model="introduce" :min-height="192" :height="192" />
          <div class="bottom">
            <el-button v-if="!underReview" type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
            <el-button v-if="underReview" type="primary" @click="underReviewTips" disabled>正在审核</el-button>
            <el-button v-if="!underReview" @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "../components/Editor/index.vue";

export default {
  name: "HiringPlatform1EnterpriseSettlement",
  components: { Editor },
  data() {
    return {
      underReview: false,
      introduce: "",
      ruleForm: {
        serviceType: 1,
        earnestMoney: "1000",
        serviceIndustry: "",
        serviceName: "",
        serviceLocation: "",
        detailedAddress: "",
        contactInformation: "",
        licenseImg: "", //营业执照
        idcardImg: "", //身份证
        idcardImgz: "", //身份证 正面
        idcardImgf: "", //身份证 反面
        providerLabel: "", //标签
      },
      rules: {
        serviceType: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        providerLabel: [
          { required: true, message: "请选择标签", trigger: "blur" },
        ],
        earnestMoney: [
          { required: true, message: "请填写保证金", trigger: "blur" },
        ],
        serviceIndustry: [
          { required: false, message: "请选择行业", trigger: "change" },
        ],
        serviceName: [
          {
            required: true,
            message: "2-15字符，不支持特殊字符",
            trigger: "blur",
          },
          {
            min: 2,
            max: 15,
            message: "长度在 2 到 15 个字符",
            trigger: "blur",
          },
        ],
        serviceLocation: [
          { required: true, message: "请选择服务商所在地", trigger: "change" },
        ],
        detailedAddress: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
        contactInformation: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        // 身份证
        idcardImgz: [
          { required: true, message: "请上传身份证正面", trigger: "blur" },
        ],
        // 营业执照
        licenseImg: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
      },
      industryOptions: [],
      areaProps:    {
        value: 'id',
        label: 'name'
      },
      StudentLabels: [],
    };
  },

  created() {
    if (this.$store.getters.getUser) this.checkExist();
  },
  mounted() {
    this.ruleForm.contactInformation = this.$store.getters.getUser.userPhone;

  },
  computed: {
    serviceItems() {
      return this.$store.getters.getConfig.serviceItemsList;
    },
    city() {
      return this.$store.getters.getCity;
    },
    labels() {
      return this.$store.getters.getConfig.serviceProviderLabels
    }
  },
  methods: {
    checkExist() {
      let url = `/app-api/hiring/service-provider-info/get/user?userId=${this.$store.getters.getUser.id}`;
      this.$axios
          .get(url)
          .then((res) => {
            if (res.data.code === 0) {
              const data = res.data.data;
              // 行业转换
              // for (let x in this.industryOptions) {
              //   if (x == data.providerIndustry) {
              //     data.providerIndustry = this.industryOptions[x];
              //   }
              // }
              data.providerLocation = [data.providerProvince, data.providerCity];

              this.ruleForm.serviceType = data.providerType;
              this.ruleForm.providerLabel = data.providerLabel;
              // this.ruleForm.earnestMoney = data.earnestMoney;
              this.ruleForm.serviceIndustry = data.providerIndustry;
              this.ruleForm.serviceName = data.providerName;
              this.ruleForm.serviceLocation = [data.providerProvince, data.providerCity];
              this.ruleForm.detailedAddress = data.providerAddress;
              // this.ruleForm.contactInformation = data.userPhone;
              this.ruleForm.idcardImgz = data.idcardImgz = data.idcardImg.split(",")[0];
              this.ruleForm.idcardImgf = data.idcardImgf = data.idcardImg.split(",")[1];
              this.introduce = data.providerContents;

              if (data.serviceType === 1) {
                this.ruleForm.licenseImg = data.licenseImg;
              }

              this.underReview = data.providerState === 1

            } else {
              this.$message.warning(res.data.message);
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    submitForm(formName) {
      if (!this.$store.getters.getUser) {
        this.$notify.info('您还未登录，请先登录');
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.introduce === "") {
            return this.$message.warning("请填写介绍信息");
          }
          if (!this.ruleForm.idcardImgf) {
            return this.$message.warning("请上传身份证反面");
          }
          let payload = {
            userId: this.$store.getters.getUser.id,
            providerType: this.ruleForm.serviceType, //类型
            deposit: this.ruleForm.earnestMoney, //保证金
            providerIndustry: this.ruleForm.serviceIndustry, //行业
            providerName: this.ruleForm.serviceName, //名称
            userPhone: this.ruleForm.contactInformation, //联系方式
            providerAddress: this.ruleForm.detailedAddress, //详细地址
            providerProvince: this.ruleForm.serviceLocation[0], //省
            providerCity: this.ruleForm.serviceLocation[1], //市
            providerContents: this.introduce, //介绍
            licenseImg: this.ruleForm.licenseImg,
            idcardImg: [
              this.ruleForm.idcardImgz,
              this.ruleForm.idcardImgf,
            ].join(","),
            providerLabel: this.ruleForm.providerLabel,
          };
          this.$axios
            .post("/app-api/hiring/service-provider-info/create", payload)

            .then((res) => {
              if (res.data.code === 0) {
                this.$modal.notifySuccess("提交成功!等待人工审核！");
                this.$router.push({ path: "/UserInfo/personInfo" });
              }
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          this.$nextTick(() => {
            this.$message.warning("请填写完信息,在提交!");
            let isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: "center",
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: "smooth",
            });
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.introduce = "";
      this.ruleForm.idcardImgf = "";
    },
    // 获取行业
    getIndustryOptions() {
      console.log(this.$store.getters.getConfig)
      this.industryOptions = this.$store.getters.getConfig.serviceItemsList;
    },

    serviceTypeChange(value) {
      this.ruleForm.earnestMoney = value === 1 ? 1000 : 2000;
    },
    underReviewTips() {
      this.$message.info('正在审核，请稍后');
    }
  },
};
</script>

<style lang="less" scoped>
.EnterpriseSettlement {
  .enterpriseSettlement {
    width: 1225px;
    margin: 0 auto;
    .from-data {
      border-radius: 8px;
      .idcardImg {
        display: flex;
        .idcardImg-rule {
          font-weight: 400;
          color: #999999;
          line-height: 18px;
          font-size: 14px;
          margin-left: 20px;
          width: 257px;
        }
        .idcardImgf {
          margin-left: 20px;
        }
      }
    }
  }
}
.inputWidth {
  width: 217px;
}

.head {
  margin-bottom: 20px;
  background-color: #fff;
  color: #333;
  display: flex;
  height: 60px;
  align-items: center;
}

.imgs {
  margin: auto 30px;
  width: 80px;
  height: 45px;
  background-color: #ccc;
  // margin-right: 30px;
}

.secondCard {
  margin-top: 20px;
  border-radius: 8px;

  .introduce {
    margin: 20px;
    font-size: 24px;
    font-weight: 400;
    color: #000;
  }

  .bottom {
    margin: 20px auto;
    text-align: center;
  }
}
</style>
